footer{
    margin: 0 auto;
    height: 30px;
    padding-top: 20px;
    width: 100%;
    text-align: center;
}
footer ul{
    margin: 0;
    padding:0;
}
footer li {
    list-style-type: none;
    line-height: 9px;
    height: 11px;
    border-right: 1px solid #354052;
    padding: 0px 10px;
    display:inline;
}
footer li a{
    text-decoration: none;
    color: #354052;
    font-size: 12px;
}