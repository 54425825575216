.SurveyTile {
    transition: box-shadow .3s;
    width: auto;
    margin: 2px;
    border-radius:10px;
    border: 0px solid #ccc;
    background: #fff;
    float: left;
}

.SurveyTile:hover {
    box-shadow: 0 0 16px rgba(33,33,33,.5);
}

.SurveyTile > .card > .card-img-overlay {
    top: auto;
}